import React from 'react';

const Context = React.createContext({
    configuracoes: {},
    setConfiguracoes: () => { },
    modal: false,
    setModal: () => { },
    activePage: false,
    setActivePage: () => { }
});

export default Context;