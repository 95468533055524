import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ScrollToTop from "./Components/ScrollToTop";

import { Suspense, lazy, useState, useEffect } from 'react';

import Context from './Context';
import axios from "axios";

import { Helmet, HelmetProvider } from "react-helmet-async";

const Header = lazy(() => import('./Components/Header'));
const Footer = lazy(() => import('./Components/Footer'));
const Cookies = lazy(() => import('./Components/Cookies'));

const Home = lazy(() => import('./Pages/Home'));
const Empresa = lazy(() => import('./Pages/Empresa'));
const Servicos = lazy(() => import('./Pages/Servicos'));
const Contato = lazy(() => import('./Pages/Contato'));
const Privacidade = lazy(() => import('./Pages/Privacidade'));
const Termos = lazy(() => import('./Pages/Termos'));


function App() {
  localStorage.setItem("apiUrl", "/sistema/");
  const [configuracoes, setConfiguracoes] = useState({});
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState("Home");
  const value = { configuracoes, setConfiguracoes, modal, setModal, activePage, setActivePage };

  useEffect(() => {
    axios.get(`${localStorage.getItem("apiUrl")}api/configuracoes`).then(response => {
      if (response.data.status === 200) {
        setConfiguracoes(response.data.success.configuracoes);
      } else {
        setModal({
          titulo: "Ocorreu um erro!",
          texto: "Por favor, recarregue a página!",
          botao: "Voltar ao site"
        })
      }
    });
  }, []);

  return (
    <HelmetProvider>
      <Context.Provider value={value}>
        <div id="modal" className={modal ? "active" : ""}>
          {modal && <div className="content">
            <h3>{modal.titulo}</h3>
            <p>{modal.texto}</p>
            <button type="button" onClick={() => { if (modal.onExit) { modal.onExit() } setModal(false) }}>{modal.botao}</button>
          </div>}
        </div>
        <Helmet>
          <title>Fix Informática</title>
        </Helmet>
        <Router>
          <ScrollToTop />
          <div className="App">
            <Suspense fallback={<div></div>}>
              <Cookies />
              <Header />
              <Switch>
                <Route path="/" exact>
                  <Home />
                </Route>
                <Route path="/empresa" exact>
                  <Empresa />
                </Route>
                <Route path="/servicos" exact>
                  <Servicos />
                </Route>
                <Route path="/contato" exact>
                  <Contato />
                </Route>
                <Route path="/privacidade" exact>
                  <Privacidade />
                </Route>
                <Route path="/termos" exact>
                  <Termos />
                </Route>
              </Switch>
              <Footer />
            </Suspense>
          </div>
        </Router>
      </Context.Provider>
    </HelmetProvider>
  );
}

export default App;
